import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import Embed from "@onnit-js/ui/components/video/Embed";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";

interface Props {
    scrollToForm?: Function;
}

const Summit: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = ({ children }) => (
    <TwelveColumn py={6} display={["block", "block", "grid"]} justifyContent="center">
        <Box gridColumn={["3 / span 8"]} py={[0, 0, 5]} px={[6, 6, 6, 8]}>
            {children}
            <Embed src="https://player.vimeo.com/video/877174687" title="Onnit 6 Summit" />
        </Box>
    </TwelveColumn>
);

export default Summit;
