import React from "react";
import { Text } from "@onnit-js/ui/components/text";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import colors from "./colors";

const Rules = () => (
    <TwelveColumn
        py={[6, 6, 6, 8]}
        display={["block", "block", "grid"]}
        gridTemplateRows="auto"
        px={4}
        bg={colors.secondBackground}
    >
        <Text
            as="h3"
            typeStyle="title02"
            gridColumn={["4 / span 7", "4 / span 7", "4 / span 7", "5 / span 6"]}
            mb={[6, 6, 7]}
        >
            $100K Onnit 6 Challenge Rules
        </Text>
        <Text
            as="h3"
            typeStyle="text01"
            fontWeight="black"
            gridRow="2"
            gridColumn={["1 / span 3", "1 / span 3", "1 / span 3", "2 / span 3"]}
            textAlign={["left", "left", "right"]}
            style={{ textTransform: "capitalize" }}
            mr={8}
            mb={2}
        >
            Train
        </Text>
        <Text
            as="p"
            typeStyle="text02"
            gridRow="2"
            gridColumn={["4 / span 7", "4 / span 7", "4 / span 7", "5 / span 6"]}
            mb={[6, 6, 8]}
        >
            Choose any of our{" "}
            <a href="/six/" target="_blank">
                Onnit 6 (O6) Programs
            </a>{" "}
            or{" "}
            <a href="/onnit-in-30/" target="_blank">
                Onnit In 30 Programs
            </a>{" "}
            and commit to them for 12 weeks. (Each O6 program runs six weeks, so you can combine it with another O6
            program, or Onnit In 30, to keep training all 12 weeks.) All the workouts can be streamed on your
            computer and done at home. (See Find Your Workout below for more details.)
        </Text>

        <Text
            as="h3"
            typeStyle="text01"
            fontWeight="black"
            gridRow="3"
            gridColumn={["1 / span 3", "1 / span 3", "1 / span 3", "2 / span 3"]}
            textAlign={["left", "left", "right"]}
            style={{ textTransform: "capitalize" }}
            mr={8}
            mb={2}
        >
            Engage
        </Text>
        <Text
            as="p"
            typeStyle="text02"
            gridRow="3"
            gridColumn={["4 / span 7", "4 / span 7", "4 / span 7", "5 / span 6"]}
            mb={[6, 6, 8]}
        >
            <a href="https://www.facebook.com/groups/onnittribe/" target="_blank" rel="noreferrer">
                Connect with other O6 Challengers
            </a>
            , including Onnit employees, to compare notes, share healthy recipes, and motivate each other to get the
            best results. Join the{" "}
            <a href="https://www.facebook.com/groups/onnittribe/" target="_blank" rel="noreferrer">
                Onnit Tribe on Facebook
            </a>
            , or{" "}
            <a href="https://discord.com/invite/xq77TCVFGN" target="_blank" rel="noreferrer">
                Discord
            </a>
            , and engage with other contestants by using the hashtag{" "}
            <a href="https://www.facebook.com/groups/onnittribe/" target="_blank" rel="noreferrer">
                #Onnit6Challenge
            </a>
            .
        </Text>

        <Text
            as="h3"
            typeStyle="text01"
            fontWeight="black"
            gridRow="4"
            gridColumn={["1 / span 3", "1 / span 3", "1 / span 3", "2 / span 3"]}
            textAlign={["left", "left", "right"]}
            style={{ textTransform: "capitalize" }}
            mr={8}
            mb={2}
        >
            Share
        </Text>
        <Text
            as="p"
            typeStyle="text02"
            gridRow="4"
            gridColumn={["4 / span 7", "4 / span 7", "4 / span 7", "5 / span 6"]}
            mb={[6, 6, 8]}
        >
            After 12 weeks, be ready to answer specific questions about how the Challenge impacted your mental and
            physical well-being.
            <br />
            <br />
        </Text>
        <Text
            typeStyle="text01"
            fontSize={[1, 1, 1, 2]}
            textAlign="center"
            gridRow="5"
            gridColumn="3 / span 8"
            fontWeight="regular"
            fontStyle="italic"
            mb={4}
        >
            Grand prize winners will be determined by vote—Onnit staff and Onnit community members decide together!
        </Text>
        <Text
            typeStyle="text01"
            fontSize={[1, 1, 1, 2]}
            textAlign="center"
            gridRow="6"
            gridColumn="3 / span 8"
            fontWeight="regular"
            fontStyle="italic"
        >
            EVERYONE who completes the Onnit 6 Challenge will receive a coupon code for 20% off Onnit products!
        </Text>
    </TwelveColumn>
);

export default Rules;
