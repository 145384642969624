import React from "react";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/light";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import Box from "@onnit-js/ui/components/box/Box";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import { Text } from "@onnit-js/ui/components/text";
import Embed from "@onnit-js/ui/components/video/Embed";
import config from "../../config/config";
import colors from "./components/2024/colors";
import useScrollToRef from "../../hooks/useScrollToRef";
import NyayHeroBanner from "./components/2024/Q1/NYAYHeroBanner";
import JoinOnnitTribeButton from "./components/shared/JoinOnnitTribeButton";
import SignUpArea from "../../components/common/SignUpArea";

export default function Page() {
    const showSuccess = window.location.search.includes("success=true");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [formRef, scrollToForm] = useScrollToRef();
    const onnitContext = useOnnitContext();

    React.useEffect(() => {
        // Scroll to the success message div
        if (showSuccess) {
            setTimeout(() => {
                scrollToForm();
            }, 3000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!onnitContext) {
        return null;
    }

    const IMGIX_URL = `${onnitContext.url.cdn.static_images}/landers/onnit-6-challenge/2024/q1/`;
    const IMGIX_PARAMS = config.IMG_PARAMS;
    const IMGIX_SVG_PARAMS = config.SVG_PARAMS;

    const textColor = "#312F2E";

    return (
        <ThemeProvider theme={theme}>
            <Box>
                <GlobalStyle
                    css={() => `
                        #page-content {
                            p a {
                                text-decoration: underline;
                            }

                            p b {
                                font-weight: 900;
                            }
                        }
                    `}
                />

                <NyayHeroBanner baseUrl={IMGIX_URL} params={IMGIX_PARAMS} svgParams={IMGIX_SVG_PARAMS} />

                <Box bg="white">

                    <Box mt={[6, 6, 8]} mx={[1, 2]}>
                        <Text as="h2" typeStyle="title01" textAlign="center" mb={4} color={textColor}>
                            This challenge has concluded
                        </Text>

                        <Text
                            as="p"
                            typeStyle="text02"
                            fontStyle="italic"
                            fontWeight="black"
                            textTransform="uppercase"
                            textAlign="center"
                            mb={4}
                            color={textColor}
                            px={[4, 4, 6]}
                        >
                            Sign up to know when the next challenge starts
                        </Text>

                    </Box>

                    <Box maxWidth={["90%", "90%", "700px", "700px", "1024px"]} mx="auto">
                        <SignUpArea
                            mt={3}
                            mx="auto"
                            bg={colors.golder}
                            iconColor="black"
                            successColor={colors.textColor}
                            smsUrl="https://onnit.attn.tv/p/VbK/landing-page"
                            hubspotFormId="09128bd6-a117-430a-a38c-1215e178600e"
                            hubspotAriaLabel="Sign up"
                            hubspotFormSource="onnit 6 challenge 2024 q1"
                        />
                    </Box>

                    <TwelveColumn pt={4} pb={4} display={["block", "block", "grid"]}>
                        <Box
                            gridColumn={["1 / span 6", "1 / span 6", "1 / span 6", "2 / span 5"]}
                            pb={4}
                            px={[6, 6, 6, 8]}
                            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
                            onClick={(event) => scrollToForm()}
                        >
                            <Embed src="https://player.vimeo.com/video/787651114" title="Previous Winners" />
                        </Box>
                        <Box
                            gridColumn={["7 / span 6", "7 / span 6", "7 / span 6", "7 / span 5"]}
                            pb={4}
                            px={[6, 6, 6, 8]}
                        >
                            <Embed src="https://player.vimeo.com/video/877174687" title="Onnit 6 Summit" />
                        </Box>
                    </TwelveColumn>

                    <JoinOnnitTribeButton
                        IMGIX_URL={IMGIX_URL}
                        IMGIX_PARAMS={IMGIX_PARAMS}
                        maxWidth={["360px", "600px", "1300px"]}
                        mx={4}
                        mb={[8, 6]}
                    />

                </Box>
            </Box>
        </ThemeProvider>
    );
}
