export default {
    bgGrey: "#FFFEFA",
    secondBackground: "#F4F4F4",
    grey: "#C5CCC4",
    blueGrey: "#2E5762",
    lightGreen: "#7F967B",
    goldish: "#FAD990",
    golder: "#E6C754",
    black: "#000000",
    textColor: "#05252D",
    bodyTextColor: "#ffffff",
    tabBgColor: "#C5CCC4",
    tabBgActiveColor: "#D0B16D",
    buttonColor: "#E6C754",
    orange: "#F7A80A",
    deepTeal: "#05252D",
};
