import React from "react";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/light";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import Box from "@onnit-js/ui/components/box/Box";
import { Text } from "@onnit-js/ui/components/text";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import ImportantDates, { DateRow } from "./components/ImportantDates";
import config from "../../config/config";
import useScrollToRef from "../../hooks/useScrollToRef";
import colors from "./components/2024/colors";
import NyayHeroBanner from "./components/2024/Q1/NYAYHeroBanner";
import PrizeList from "./components/2024/PrizeList";
import { beforeDate } from "../../lib/utils";
import Twitter from "./components/shared/icons/Twitter";
import Facebook from "./components/shared/icons/Facebook";
import { Finalists } from "./components/2024/Finalists";
import finalists from "./finalists";
import SurveyMonkeyFormEmbed from "../../components/common/SurveyMonkeyFormEmbed";

export default function Page() {
    const [formRef, scrollToForm] = useScrollToRef();
    const showSuccess = window.location.search.includes("success=true");
    const onnitContext = useOnnitContext();

    React.useEffect(() => {
        // Scroll to the success message div
        if (showSuccess) {
            setTimeout(() => {
                scrollToForm();
            }, 1500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!onnitContext) {
        return null;
    }

    const IMGIX_URL = `${onnitContext.url.cdn.static_images}/landers/onnit-6-challenge/2024/q1/`;
    const IMGIX_PARAMS = config.IMG_PARAMS;
    const IMGIX_SVG_PARAMS = config.SVG_PARAMS;

    const showVotingForm = beforeDate("2024-04-20 23:59:59");
    const randomizedFinalists = finalists.sort(() => Math.random() - 0.5);

    return (
        <ThemeProvider theme={theme}>
            <Box>
                <GlobalStyle
                    css={() => `
                        #page-content {
                            background-color: ${colors.bgGrey};

                            p a {
                                text-decoration: underline;
                            }

                            p b {
                                font-weight: 900;
                            }
                        }
                    `}
                />

                {/* NYAY Sale */}
                <NyayHeroBanner baseUrl={IMGIX_URL} params={IMGIX_PARAMS} svgParams={IMGIX_SVG_PARAMS} />

                {/* Onnit 6 Register */}
                <Box py={[8, 6, 8]} background="linear-gradient(#94F4FC, #ffffff 50%)">
                    <Box maxWidth={["600px", "600px", "600px", "800px"]} mx="auto" px={4}>
                        <Text as="p" typeStyle="title01" mb={6}>
                            Vote for the $100K Onnit 6 Challenge Finalists!
                        </Text>
                        <Text as="p" typeStyle="text02" mb={[4, 6]}>
                            The Onnit team has selected 18 finalist candidates. Watch their videos below, and then
                            decide whom you think deserves to be one of the 10 champions. Cast your votes by
                            entering your email, and you’ll receive a coupon for 15% off anything on onnit.com!
                        </Text>
                        <Text as="p" typeStyle="text02" mb={[4, 6]}>
                            Online voting lasts until April 20. Be sure to cast your vote by the deadline. You must
                            enter your email for your vote to count; voters get one vote per email address.
                        </Text>
                        <Text as="p" typeStyle="title01">
                            Voting is open now!
                        </Text>
                    </Box>
                </Box>

                <TwelveColumn>
                    <Box gridColumn={["1 / -1", "1/-1", "1 / span 12", "2 /span 10"]} bg="#F4F4F4" pb={4} mx={4}>
                        <TwelveColumn>
                            <Box gridColumn={["1 / -1", "1/-1", "2 / span 10", "2 /span 10"]} px={[4, 0]}>
                                <Text as="p" typeStyle="title01" my={4}>
                                    Meet our<br />Finalists
                                </Text>
                                <Text typeStyle="text03">
                                    Help your favorite finalist get more<br />
                                    votes by sharing this page on social media!
                                </Text>
                                <Box mt={2}>
                                    <Text
                                        as="a"
                                        display="inline"
                                        noExternalLinkIcon
                                        title="Share to Facebook"
                                        href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fonnit.com%2Fonnit-6-challenge"
                                        target="_blank"
                                    >
                                        <Facebook width={35} height={35} />
                                    </Text>
                                    <Text
                                        as="a"
                                        ml={3}
                                        display="inline"
                                        noExternalLinkIcon
                                        title="Share to X"
                                        href="https://www.twitter.com/share?url=https://www.onnit.com/onnit-6-challenge"
                                        target="_blank"
                                    >
                                        <Twitter width={35} height={35} />
                                    </Text>
                                </Box>
                            </Box>
                        </TwelveColumn>
                    </Box>
                </TwelveColumn>

                <Finalists finalists={randomizedFinalists} baseImgUrl={IMGIX_URL} />

                {showVotingForm && (
                    <Box ref={formRef}>
                        <TwelveColumn>
                            <Box gridColumn={["1 / -1", "1/-1", "1 / span 12", "2 /span 10"]} pb={4} mx={4}>
                                <TwelveColumn>
                                    <Box gridColumn={["1 / -1", "1/-1", "2 / span 10", "2 /span 10"]} px={[4, 0]}>
                                        <Text as="p" typeStyle="title01" my={4}>
                                            Vote for our Finalists
                                        </Text>
                                        <Text typeStyle="text03">
                                            To cast your vote, select your top 10 finalists and hit the submit button on
                                            the page!<br />
                                        </Text>
                                    </Box>
                                </TwelveColumn>
                            </Box>
                        </TwelveColumn>
                        <SurveyMonkeyFormEmbed
                            formId="tRaiETqnLgj758hTBazgd5zlCwGOLpiSe0MNRph_2BOVycOeHebQ3VRSgCdor1N0tU"
                        />
                    </Box>
                )}

                <PrizeList scrollToRef={scrollToForm} baseImgUrl={IMGIX_URL} showSignUp={false} />

                <Box bg="white" py={[6, 6, 8]}>
                    <ImportantDates
                        termsLink="https://2252779.fs1.hubspotusercontent-na1.net/hubfs/2252779/100k Onnit 6 Challenge Ts & Cs.pdf"
                        termsColor={colors.black}
                        backgroundColor={colors.secondBackground}
                        px={4}
                        py={[6, 6, 6, 8]}
                    >
                        <DateRow
                            date="April 1"
                            endDate="2024-04-01 23:59:59"
                            text="Application submission begins. Watch for instructions on how to submit your results. We’ll be reaching out soon and asking you to answer specific questions about how the Challenge and your Onnit program changed your body and your mindset."
                            dateColor={colors.black}
                        />
                        <DateRow
                            date="April 5"
                            endDate="2024-04-05 23:59:59"
                            text="The application submission period ends today."
                            dateColor={colors.black}
                        />
                        <DateRow
                            date="April 14"
                            endDate="2024-04-14 23:59:59"
                            text="Semi-finalist video submissions due."
                            dateColor={colors.black}
                        />
                        <DateRow
                            date="April 16 - 20"
                            endDate="2024-04-20 23:59:59"
                            text="Online voting by the Onnit community to determine the winners."
                            dateColor={colors.black}
                        />
                        <DateRow date="April 24" text="Winners announced!" dateColor={colors.black} />
                    </ImportantDates>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
