import React from "react";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/light";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import Box from "@onnit-js/ui/components/box/Box";
import Embed from "@onnit-js/ui/components/video/Embed";
import { Text } from "@onnit-js/ui/components/text";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import { Img } from "@onnit-js/ui/components/image";
import Button from "@onnit-js/ui/components/button/Button";
import ImportantDates, { DateRow } from "./components/ImportantDates";
import config from "../../config/config";
import useScrollToRef from "../../hooks/useScrollToRef";
import colors from "./components/2024/colors";
import Rules from "./components/2024/Rules";
import NyayHeroBanner from "./components/2024/Q1/NYAYHeroBanner";
import ChallengeDescription from "./components/2024/ChallengeDescription";
import PrizeList from "./components/2024/PrizeList";
import Summit from "./components/2024/Summit";
import ShopDigital from "./components/2024/ShopDigital";
import BuildProgram from "./components/2024/BuildProgram";
import Articles from "./components/2024/Articles";
import AlphaBrainPreWorkoutFlashBanner from "./components/shared/AlphaBrainPreWorkoutFlashBanner";
import JoinOnnitTribeButton from "./components/shared/JoinOnnitTribeButton";
import SignUpArea from "../../components/common/SignUpArea";

export default function Page() {
    const [formRef, scrollToForm] = useScrollToRef();
    const onnitContext = useOnnitContext();

    if (!onnitContext) {
        return null;
    }

    const IMGIX_URL = `${onnitContext.url.cdn.static_images}/landers/onnit-6-challenge/2024/q1/`;
    const IMGIX_PARAMS = config.IMG_PARAMS;
    const IMGIX_SVG_PARAMS = config.SVG_PARAMS;

    return (
        <ThemeProvider theme={theme}>
            <Box>
                <GlobalStyle
                    css={() => `
                        #page-content {
                            background-color: ${colors.bgGrey};

                            p a {
                                text-decoration: underline;
                            }

                            p b {
                                font-weight: 900;
                            }
                        }
                    `}
                />

                {/* NYAY Sale */}
                <NyayHeroBanner baseUrl={IMGIX_URL} params={IMGIX_PARAMS} svgParams={IMGIX_SVG_PARAMS} />

                {/* Onnit 6 Challenge */}
                <Box bg="black" py={[8, 6, 8]}>
                    <TwelveColumn display={["block", "grid", "grid", "grid"]}>
                        <Box gridColumn={["1 / -1", "1 / span 10", "3 / span 10", "4 / span 10", "5 / span 10"]} px={4}>
                            <Text as="p" color="white" typeStyle="title01">This Onnit 6<br />challenge has<br />already
                                started ...
                            </Text>
                        </Box>
                    </TwelveColumn>
                    <Box
                        maxWidth={["auto", "500px"]}
                        mx={["", "", "auto"]}
                        px={[4, 4, 0]}
                        textAlign={["left", "left", "center"]}
                    >
                        <Text
                            mt={4}
                            fontSize={[4, 5, 5, 5]}
                            textAlign={["left", "left", "center"]}
                            as="p"
                            color={colors.orange}
                            typeStyle="title04"
                        >Sign up to know when the next<br />challenge
                            starts
                        </Text>
                    </Box>
                    <Box maxWidth={["90%", "90%", "700px", "700px", "1024px"]} mx="auto">
                        <SignUpArea
                            mt={6}
                            mx="auto"
                            bg={colors.secondBackground}
                            iconColor="#707070"
                            successColor={colors.textColor}
                            smsUrl="https://onnit.attn.tv/p/VbK/landing-page"
                            hubspotFormId="09128bd6-a117-430a-a38c-1215e178600e"
                            hubspotAriaLabel="Sign up"
                            hubspotFormSource="onnit 6 challenge 2024 q1"
                        />
                    </Box>

                </Box>

                <Box bg={colors.secondBackground}>
                    <ChallengeDescription>
                        <Embed src="https://player.vimeo.com/video/877177682" title="Onnit 6 Challange Testimonials" />
                    </ChallengeDescription>
                </Box>

                <AlphaBrainPreWorkoutFlashBanner
                    mt={[4, 6, 8]}
                    IMGIX_URL={IMGIX_URL}
                    IMGIX_PARAMS={IMGIX_PARAMS}
                    IMGIX_SVG_PARAMS={IMGIX_SVG_PARAMS}
                />

                <PrizeList scrollToRef={scrollToForm} baseImgUrl={IMGIX_URL} showSignUp={false} />
                <Summit scrollToForm={scrollToForm} />
                <JoinOnnitTribeButton IMGIX_URL={IMGIX_URL} IMGIX_PARAMS={IMGIX_PARAMS} />
                <Rules />

                <Box bg="white" py={[6, 6, 8]} ref={formRef}>
                    <Box>
                        <TwelveColumn display={["block", "grid", "grid", "grid"]}>
                            <Box gridColumn={["1 / -1", "1 / span 12", "1 / span 12", "2 / span 10"]} px={4}>
                                <Img
                                    src={`${IMGIX_URL}/2024-100K-WPage-hashtag-anamorphic.png${config.IMG_PARAMS}`}
                                    alt="Man using kettlebell"
                                    width={1029}
                                    height={999}
                                />
                            </Box>
                        </TwelveColumn>
                        <TwelveColumn
                            display={["block", "grid", "grid", "grid"]}
                            px={4}
                            alignItems="center"
                            alignContent="center"
                        >
                            <Box gridColumn={["1 / -1", "1 / span 6", "1 / span 6", "2 / span 6"]} my={4}>
                                <Text as="p" typeStyle="text03">Connect with other challengers. By using the hashtag
                                    #onnit6challenge, you can connect with other challengers to compare notes, share
                                    healthy recipes, and motivate each other to get the best results.
                                </Text>
                            </Box>
                            <Box
                                gridColumn={["1 / -1", "8 / span 5", "8 / span 5", "8 / span 4"]}
                                px={[4, 4, 0]}
                                textAlign={["center", "right"]}
                                my={4}
                            >
                                <Button
                                    size={["medium", "medium", "large"]}
                                    onClick={() => window.open("https://www.facebook.com/groups/onnittribe/", "_blank")}
                                >
                                    Join our Facebook Group - Onnit Tribe
                                </Button>
                            </Box>
                        </TwelveColumn>
                    </Box>

                    <ShopDigital baseImgUrl={IMGIX_URL} />

                    <BuildProgram />

                    <ImportantDates
                        termsLink="https://2252779.fs1.hubspotusercontent-na1.net/hubfs/2252779/100k Onnit 6 Challenge Ts & Cs.pdf"
                        termsColor={colors.black}
                        backgroundColor={colors.secondBackground}
                        px={4}
                        py={[6, 6, 6, 8]}
                    >
                        <DateRow
                            date="November 6"
                            endDate="2023-11-06 23:59:59"
                            text="$100K Onnit 6 Challenge registration begins."
                            dateColor={colors.black}
                        />
                        <DateRow
                            date="January 7"
                            endDate="2024-01-07 23:59:59"
                            text="Registration ends."
                            dateColor={colors.black}
                        />
                        <DateRow
                            date="January 8"
                            endDate="2024-01-08 23:59:59"
                            text="The Challenge begins!"
                            dateColor={colors.black}
                        />
                        <DateRow
                            date="March 31"
                            endDate="2024-03-31 23:59:59"
                            text="Last day, best day. The final workout of the program should feel bittersweet. Give it everything you can and have fun. You’re almost done."
                            dateColor={colors.black}
                        />
                        <DateRow
                            date="April 1"
                            endDate="2024-04-01 23:59:59"
                            text="Application submission begins. Watch for instructions on how to submit your results. We’ll be reaching out soon and asking you to answer specific questions about how the Challenge and your Onnit program changed your body and your mindset."
                            dateColor={colors.black}
                        />
                        <DateRow
                            date="April 5"
                            endDate="2024-04-05 23:59:59"
                            text="The application submission period ends today."
                            dateColor={colors.black}
                        />
                        <DateRow
                            date="April 14"
                            endDate="2024-04-14 23:59:59"
                            text="Semi-finalist video submissions due."
                            dateColor={colors.black}
                        />
                        <DateRow
                            date="April 16 - 20"
                            endDate="2024-04-20 23:59:59"
                            text="Online voting by the Onnit community to determine the winners."
                            dateColor={colors.black}
                        />
                        <DateRow
                            date="April 24"
                            text="Winners announced!"
                            dateColor={colors.black}
                        />
                    </ImportantDates>

                    <Articles baseImgUrl={IMGIX_URL} />
                </Box>
            </Box>
        </ThemeProvider>
    );
}
