import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import { Text } from "@onnit-js/ui/components/text";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";

const ChallengeDescription: React.FC<React.PropsWithChildren<React.PropsWithChildren<{}>>> = ({ children }) => (
    <TwelveColumn display={["block", "block", "grid"]} py={4}>
        <Box gridColumn={["7 / span 6", "7 / span 6", "7 / span 6", "7 / span 5"]} p={4}>
            {children}
        </Box>
        <Box gridColumn={["1 / span 6", "1 / span 6", "1 / span 6", "2 / span 5"]} p={4}>
            <Text as="h2" typeStyle="text02" textTransform="uppercase" mb={3}>
                10 Grand Prize Winners
            </Text>
            <Text as="h2" typeStyle="title01" mb={5}>
                $100K Up For Grabs
            </Text>
            <Text as="p" typeStyle="text03" mb={3}>
                Similar to the Onnit 6 Challenges of the past, the $100K Onnit 6 Challenge is a fitness
                transformation competition where you can connect with other Onnit fans to help you set
                healthy habits that change your body and your life. The Challenge runs 12 weeks, and 10
                winners will receive $10,000—a total of $100K in prize money—and more.
            </Text>
            <Text as="p" typeStyle="text03">
                Choose from any of the Onnit 6 or Onnit In 30 programs to participate.
            </Text>
        </Box>
    </TwelveColumn>
);

export default ChallengeDescription;
