import { Text } from "@onnit-js/ui/components/text";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import { Img } from "@onnit-js/ui/components/image";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { FaChevronRight } from "react-icons/fa";
import React from "react";
import config from "../../../../config/config";

export interface Finalist {
    name: string;
    location?: string;
    blurb: string;
    img: string;
    imgAltText: string;
    url?: string;
}

export type Finalists = Finalist[];

interface FinalistProps extends BoxProps {
    finalists: Finalists;
    folder?: string;
    baseImgUrl: string;
}

export const Finalists = ({ finalists, folder = "finalists/", baseImgUrl, ...rest }: FinalistProps) => (
    <Box py={[4, 4, 4, 6]} {...rest}>
        <Box
            display={["grid", "grid", "grid"]}
            gridTemplateColumns={["repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(4, 1fr)"]}
            px={[0, 0, 6]}
            maxWidth={1564}
            gridGap={[4, 6]}
            mx="auto"
        >
            {finalists.map((finalist, index: number) => (
                <Box
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={`finalist-${index}`}
                    as={finalist.url ? "a" : "div"}
                    href={finalist.url}
                    title="View Video Entry"
                    target={finalist.url ? "_blank" : ""}
                    px={[4, 4, 6]}
                    py={4}
                    my={[3, 3, 0]}
                    style={{ textDecoration: "none" }}
                    display={["flex", "flex", "block"]}
                    alignItems="flex-start"
                    backgroundColor="#F4F4F4"
                >
                    <Box p={4}>
                        <Box mb={4} flex="0 0 90px">
                            <Img
                                placeholder={[460, 460]}
                                src={`${baseImgUrl}/${folder}${finalist.img}${config.IMG_PARAMS}`}
                                alt={finalist.imgAltText}
                            />
                        </Box>
                        <Box display="flex" alignItems="stretch" width={1}>
                            <Box>
                                <Text as="p" typeStyle="title04">
                                    {finalist.name}<br />
                                    <Text
                                        pt={1}
                                        as="span"
                                        typeStyle="title04"
                                        style={{ fontSize: "11px" }}
                                        dangerouslySetInnerHTML={{ __html: finalist.location ? finalist.location : "&nbsp;" }}
                                    />
                                </Text>
                                <Text
                                    as="p"
                                    typeStyle="text04"
                                    mt={2}
                                    dangerouslySetInnerHTML={{ __html: finalist.blurb }}
                                />
                            </Box>
                            {finalist.url && (
                                <Box>
                                    <Icon ml="auto" minWidth={18} color="black" icon={FaChevronRight} />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            ))}
        </Box>
    </Box>
);
