import { Finalists } from "./components/2024/Finalists";

const finalists: Finalists = [
    {
        name: "Britain Callen",
        location: "Ohio",
        blurb: "The Onnit Challenge helped me regain confidence in my own body and prepare it for the ultimate challenge of giving birth.",
        img: "02b_BritainCallen.jpg",
        imgAltText: "Britain Callen",
        url: "https://www.youtube.com/watch?v=p-tbutGa9EE"
    },
    {
        name: "Spencer Harlan",
        location: "Illinois",
        blurb: "I started the challenge in a rough position but made great strides in my physical and mental well being. Despite having a health emergency during the challenge I still finished strong, lost 29 pounds, gained strength and learned that I have more resilience than I thought. I also felt the full support of the Onnit tribe during that trying time.",
        img: "03a_SpencerHarlan.jpg",
        imgAltText: "Spencer Harlan",
        url: "https://www.youtube.com/watch?v=j1glG_8h-aU"
    },
    {
        name: "Jason Oberuc",
        location: "Minnesota",
        blurb: "I would not be where I am today without these programs and this community. I have been able to make life long changes that would not have been possible without Onnit.",
        img: "04b_JasonOberuc.jpg",
        imgAltText: "Jason Oberuc",
        url: "https://youtu.be/fT7kxd6gQ5I?si=jaV0t9ZwiixG1XQT"
    },
    {
        name: "Doug Newbern",
        location: "Tennessee",
        blurb: "This year I decided instead of competing for a prize I would seek something much more valuable, becoming the person I was meant to be before succumbing to life, shrinking me to a lesser version. With the help of Onnit and the Onnit community, I am empowered, I am restored, and I am liberated! Thank you Onnit!!!!",
        img: "05a_DougNewbern.jpg",
        imgAltText: "Doug Newbern",
        url: "https://www.youtube.com/watch?v=IFoBW_e502U"
    },
    {
        name: "Sandra Magallanes",
        location: "California",
        blurb: "As a wife, and mother of 2 with autism, it’s hard finding time for yourself. This challenge was to prove to myself that I can still find time for me. My goal wasn’t about how much weight I’m going to lose but about finishing this challenge, gaining confidence, getting stronger mentally and physically, and finding time for myself.",
        img: "06c_SandraMagallanes.jpg",
        imgAltText: "Sandra Magallanes",
        url: "https://www.youtube.com/watch?v=zhRbqG0QXhc"
    },
    {
        name: "Fay Marzuq",
        location: "Ontario, Canada",
        blurb: "I am eternally grateful to my body to go through this pregnancy at age 53. When you are my age and decide to do this, it’s a real choice. I knew I would encounter pregnancy challenges much sooner and during these last 12-weeks, I was setting a good foundation for this pregnancy and for the recovery period after birth.",
        img: "07a_FayMarzuq.jpg",
        imgAltText: "Fay Marzuq",
        url: "https://www.youtube.com/watch?v=mLn8NP0XyIM"
    },
    {
        name: "Michelle Chambless",
        location: "Oklahoma",
        blurb: "After 15 years of prioritizing my career and family, this challenge was a commitment to myself and my physical and mental well-being. The strength I’ve gained and physical transformations I have made are nothing compared to the mental gains I achieved through making my workouts and self care a priority. I’m a better wife, mother and friend because of it!",
        img: "08b_MichelleChambless.jpg",
        imgAltText: "Michelle Chambless",
        url: "https://www.youtube.com/watch?v=U3jGeFAJdGM"
    },
    {
        name: "Ryan NG",
        location: "California",
        blurb: "The steel mace goes beyond mere exercise, embodying a holistic approach that extends beyond physical fitness. Its workouts symbolize the dynamic interplay of mind, body, and spirit within ourselves and the world around us. Embracing this metaphorical dance has enriched my personal sacred journey. I’m here for it and ready to move and grove with the collective!",
        img: "09b_RyanNg.jpg",
        imgAltText: "Ryan NG",
        url: "https://www.youtube.com/watch?v=ZkJIV7Iwuvg"
    },
    {
        name: "Monica Torres",
        location: "Michigan",
        blurb: "“If I lose myself, I lose it all.” I got this tattoo during this challenge as a reminder that no matter what you’re facing, if you are not taking care of yourself you are taking the highest risk and setting yourself and others around you for failure. An empty tank can exist, but it cannot serve its purpose without fuel.",
        img: "10b_MonicaTorres.jpg",
        imgAltText: "Monica Torres",
        url: "https://www.youtube.com/watch?v=7EuNoSTtbcg"
    },
    {
        name: "Lynn Davis",
        location: "North Carolina",
        blurb: "The Onnit 6 challenge was like coming back home. Familiar but with a different me. It helped me to begin living again and not just survive. It made me feel alive, loved, and supported.The tribe, our sassy savages, have been phenomenal and the glue to this challenge. I am forever grateful to them and to Onnit for allowing me to find  happiness and purpose again.",
        img: "11c_LynnDavis.jpg",
        imgAltText: "Lynn Davis",
        url: "https://youtu.be/9-QdOhnSHbk?si=SS3nwdcCDY9zEbOV"
    },
    {
        name: "Tino Anagnostou",
        location: "New Jersey",
        blurb: "My 12 week challenge was just that.  Challenging. Not just physically but more importantly mentally and emotionally(total human optimization?). I learned that consistency, resilience, perspective and community can carry you body, mind and spirit through the roughest of times. I am a better man and father for it and I will be forever grateful for the experience.",
        img: "12a_TinoAnagnostou.jpg",
        imgAltText: "Tino Anagnostou",
        url: "https://www.youtube.com/watch?v=ZPQE3MgJsbM"
    },
    {
        name: "Yanira Canales",
        location: "Texas",
        blurb: "The Onnit 12 Challenge helped me move from chronic pains to chronic gains. It raised my sense of competence while making me feel supported and connected with a lovely tribe. These workouts and movements were a much-needed and desired gift.",
        img: "13a_YaniraCanales.jpg",
        imgAltText: "Yanira Canales",
        url: "https://www.youtube.com/watch?v=fzn8j6nbkqs"
    },
    {
        name: "Andrew Forsstrom",
        location: "New York",
        blurb: "The Onnit Challenge gave me the opportunity to push physically and achieve things with my body that I never thought were possible. I'm grateful for the privilege to move daily.",
        img: "14c_AndrewForsstrom.jpg",
        imgAltText: "Andrew Forsstrom",
        url: "https://www.youtube.com/watch?v=5f_zSKJNSyU"
    },
    {
        name: "Mitchell Crocker",
        location: "Texas",
        blurb: "The Onnit Tribe saved me, again! I lost over 40 lbs and 6% body fat in this challenge. This challenge and the Onnit Tribe helped me overcome some great life challenges. I have confidence, energy, muscles, and more happiness thanks to this great community or warriors! I’m going to #stayonnit with you! Thank you, Onnit Tribe!",
        img: "15b_MitchellCrocker.jpg",
        imgAltText: "Mitchell Crocker",
        url: "https://youtu.be/Kz_WEP8QSs0?si=OvHfRIg-Fw59l-ul"
    },
    {
        name: "Andre Piedade",
        location: "Connecticut",
        blurb: "I committed to this challenge. In the process, I found a new love for my wife who committed and completed the challenges along with me and pushed me to fight thru. Today, I feel hopeful for a return to a normal pain free life and hopeful to share a love of travel and adventure with my daughter, Harper.",
        img: "16c_AndrePiedade.jpg",
        imgAltText: "Andre Piedade",
        url: "https://www.youtube.com/watch?v=PYn3saOkyzU"
    },
    {
        name: "Travis Krizay",
        location: "Arizona",
        blurb: "Over the past 8 years of my Onnit health journey, life's thrown curveballs—I fell in love, became a father, lost a son, grew my family, opened a business, and learned to lead by example. Onnit 6 programs provided tools and discipline, helping me strive to be my best self and enjoy the journey.",
        img: "17c_TravisKrizay.jpg",
        imgAltText: "Travis Krizay",
        url: "https://www.youtube.com/watch?v=BtgzeL2Tok8"
    },
    {
        name: "Deborah Thompson",
        location: "Ohio",
        blurb: "The Onnit challenge, transcending a mere fitness regime, was a holistic journey nurturing my mind, body, and spirit. It deepened my patience, self-trust, and community engagement, altering my approach to challenges. Learning to slow down, trust the process, and embrace community support, it laid a foundation for ongoing growth and well-being.",
        img: "18a_DeborahThompson.jpg",
        imgAltText: "Deborah Thompson",
        url: "https://www.youtube.com/watch?v=pyrGbk5l0ig"
    },
    {
        name: "Gerard Michno",
        location: "Connecticut",
        blurb: "Onnit's challenge wasn't just physical. It boosted my energy, crushed stress, and instilled accomplishment in everything I did. This well-being radiated outward, strengthening relationships and skyrocketing productivity. It transformed me from prioritizing tasks that seemed like chores to prioritizing health, shaping a happier future for my family and me.",
        img: "19a_GerardMichno.jpg",
        imgAltText: "Gerard Michno",
        url: "https://www.youtube.com/watch?v=hKaO75RsFWg"
    },
];

export default finalists;
