import React from "react";
import { Img } from "@onnit-js/ui/components/image";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import Button from "@onnit-js/ui/components/button/Button";
import { Text } from "@onnit-js/ui/components/text";
import colors from "../2024/colors";

interface IJoinOnnitTribeButton extends BoxProps {
    IMGIX_URL: string;
    IMGIX_PARAMS: string;
    maxWidth?: string[];
}

const JoinOnnitTribeButton: React.FC<React.PropsWithChildren<React.PropsWithChildren<IJoinOnnitTribeButton>>> = (
    {
        IMGIX_URL,
        IMGIX_PARAMS,
        maxWidth = ["600px"],
        ...rest
    }: IJoinOnnitTribeButton) => (
        <Box justifyContent="center" {...rest}>
            <Box
                display="grid"
                style={{ cursor: "pointer" }}
                onClick={() => window.open("https://www.facebook.com/groups/onnittribe/")}
                bg={colors.buttonColor}
                mx="auto"
                maxWidth={maxWidth}
            >
                <Box display="flex" alignItems="center" p={[2, 2, 4]} gridColumn={1} gridRow={1} maxWidth="200px">
                    <Img
                        src={`${IMGIX_URL}/Onnit-Tribe-Logo.svg${IMGIX_PARAMS}&w=160`}
                        alt="Container of Alpha BRAIN"
                    />
                </Box>
                <Box
                    p={[2, 2, 4]}
                    gridColumn={["2 / span 1", "2 / span 1", 2]}
                    gridRow={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Text
                        as="p"
                        color="black"
                        typeStyle="title03"
                        id="join-the-tribe"
                        lineHeight={2}
                        fontSize={[4, 4, 6]}
                    >
                        Join the Tribe Community
                    </Text>
                </Box>
                <Box
                    p={[2, 2, 6]}
                    gridColumn={[3, 3, 4]}
                    gridRow={1}
                    display="flex"
                    alignItems="center"
                    justifyContent={["center", "center", "flex-end"]}
                >
                    <Button
                        aria-labelledby="join-the-tribe"
                        raised={false}
                        textColor="white"
                        size={["icon", "icon", "icon", "icon"]}
                    >
                        <span>&gt;</span>
                    </Button>
                </Box>
            </Box>
        </Box>
);

export default JoinOnnitTribeButton;
