import React from "react";
import { Img } from "@onnit-js/ui/components/image";
import Box from "@onnit-js/ui/components/box/Box";
import { Text } from "@onnit-js/ui/components/text";
import Button from "@onnit-js/ui/components/button/Button";
import ImageContentPortrait from "@onnit-js/ui/components/module/ImageContentPortrait";
import colors from "./colors";
import config from "../../../../config/config";

interface Props {
    baseImgUrl: string;
}

const ShopDigital = ({ baseImgUrl }: Props) => (
    <ImageContentPortrait
        py={6}
        bg={colors.bgGrey}
        image={(
            <Img
                src={`${baseImgUrl}/2024-100K-WPage-findworkout.png${config.IMG_PARAMS}`}
                alt="Man using kettlebell"
                width={1029}
                height={999}
            />
        )}
        content={(
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Text as="h2" typeStyle="title02" mb={4}>
                    Find Your Workout
                </Text>
                <Text as="p" typeStyle="text02" mb={6}>
                    Choose your Onnit 6 Challenge training from our collection of streamable training plans and
                    workouts.
                </Text>
                <Button el="a" href="/digital/" color="black" textColor="white" width={[1, 1, 300]}>
                    Shop Digital Programs
                </Button>
            </Box>
        )}
    />
);

export default ShopDigital;
