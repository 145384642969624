import React from "react";
import { Text } from "@onnit-js/ui/components/text";
import Box from "@onnit-js/ui/components/box/Box";
import Button from "@onnit-js/ui/components/button/Button";
import { Img } from "@onnit-js/ui/components/image";
import colors from "./colors";
import config from "../../../../config/config";

interface Props {
    baseImgUrl: string;
    scrollToRef: Function;
    showSignUp?: boolean;
}

const PrizeList = ({ baseImgUrl, scrollToRef, showSignUp = true }: Props) => (
    <Box pt={[6, 6, 6, 8]} mx="auto" maxWidth={[600, 600, 600, 920]}>
        <Text as="h2" typeStyle="title01" textAlign="center" px={5}>
            10 Grand-Prize<br />Winners Will<br />Receive:
        </Text>
        <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Box textAlign="center" flex="0 1 33%" p={[3, 3, 5]}>
                <Img
                    mb={2}
                    src={`${baseImgUrl}/2023-nyay-WPage-prizes-cash.svg${config.SVG_PARAMS}`}
                    alt="Illustration of Cash"
                />
                <Text typeStyle="title04" textTransform="uppercase">
                    $10,000 Cash
                </Text>
            </Box>
            <Box textAlign="center" flex="0 1 33%" p={[3, 3, 5]}>
                <Img
                    mb={2}
                    src={`${baseImgUrl}/2023-nyay-WPage-prizes-summit.svg${config.SVG_PARAMS}`}
                    alt="Illustration of Mountain"
                />
                <Text typeStyle="title04" textTransform="uppercase">
                    The Onnit Summit
                </Text>
            </Box>
            <Box textAlign="center" flex="0 1 33%" p={[3, 3, 5]}>
                <Img
                    mb={2}
                    src={`${baseImgUrl}/2023-nyay-WPage-prizes-credit.svg${config.SVG_PARAMS}`}
                    alt="Illustration of phone"
                />
                <Text typeStyle="title04" textTransform="uppercase">
                    $1,000 online credit
                </Text>
            </Box>
            <Box textAlign="center" flex="0 1 33%" p={[3, 3, 5]}>
                <Img
                    mb={2}
                    src={`${baseImgUrl}/2023-nyay-WPage-prizes-gold.svg${config.SVG_PARAMS}`}
                    alt="Illustration of gold kettlebell"
                />
                <Text typeStyle="title04" textTransform="uppercase">
                    Gold-Painted Kettlebell
                </Text>
            </Box>
            <Box textAlign="center" flex="0 1 33%" p={[3, 3, 5]}>
                <Img
                    mb={2}
                    src={`${baseImgUrl}/2023-nyay-WPage-prizes-digitalprograms.svg${config.SVG_PARAMS}`}
                    alt="Illustration of a key"
                />
                <Text typeStyle="title04" textTransform="uppercase">
                    onnit digital programs for life
                </Text>
            </Box>
        </Box>
        {showSignUp
            && (
                <Text
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    typeStyle="text01"
                    color={colors.black}
                    bg={colors.buttonColor}
                    textAlign="center"
                    textTransform="uppercase"
                    pt={[4, 6]}
                    pb={[4, 5]}
                    mt={6}
                    mx="auto"
                    mb={6}
                    onClick={() => scrollToRef()}
                    maxWidth={[300, 400, 400, 500]}
                    style={{ cursor: "pointer", textDecoration: "none" }}
                >
                    Sign Up
                    <Button
                        aria-label="Sign Up"
                        raised={false}
                        textColor="white"
                        size={["icon", "icon", "icon", "icon"]}
                        ml={[3, 6]}
                    >
                        <span>&gt;</span>
                    </Button>
                </Text>
            )};
    </Box>
);

export default PrizeList;
